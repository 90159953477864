@media (max-width: 768px) {

  [class*="col-"] {
      margin-bottom: 1rem;
  }

}

.logo {
  max-height: 90px;
  &.big {
    max-height: 120px;
  }

  @media(max-width: 991px) {
    max-height: 60px;
  }
}

.logo-white {
  filter: brightness(0) invert(1);
}

.logo-footer {
  max-height: 90px;
}

section {
  position: relative;
  padding: 8rem 0;

  &.intro {
    padding: 5rem 0 8rem;
  }

  &.padding-sm {
    padding: 3rem 0;
  }

  &.pb0 { padding-bottom: 0; }

  @media (max-width: 991px) {
    padding: 3rem 0;
  }

  &.section-img {
    min-height: 400px;
  }
}

.nav-section-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.hero {
  padding: 15rem 0;
  min-height: 450px;
  display: flex;
  align-items: center;

  &.hero-small {
    padding: 7rem 0;
  }

  @media(max-width: 991px) {
    padding: 8rem 0;
  }
}

.section-title {
  margin-bottom: 2rem;
}

.overlay {
  background-color: rgba(28, 27, 26, 0.25);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  &.strong {
    background-color: rgba(28, 27, 26, 0.5);
  }
}

.buttons {
  .button { margin: 0 8px 0 0; }
  .note { margin-top: 5px; font-size: 0.8rem; }

  @media (max-width: 991px) {
    .button {
      margin: 0 0 1rem;
      &:last-child { margin-bottom: 0; }
    }
  }
}

.separator-right-lg {
  @media(min-width: 992px) {
    border-right: 4px solid $primary;
    padding-right: 1.5rem;
  }
}

.separator-left-lg {
  @media(min-width: 992px) {
    border-left: 4px solid $primary;
    padding-left: 4rem;
  }
}

.bg-hero-intro {
  background: no-repeat center 30%;
  background-size: cover;
  padding-top: 15rem;
  padding-bottom: 12rem;
  background-image: url(../img/bg-hero-world.jpg);
}

.bg-faqs-hero {
  background: no-repeat 50% 60%;
  background-size: cover;
  background-image: url(../img/bg-faqs.jpg);
}

.bg-careers {
  background: no-repeat top center;
  background-size: cover;
  background-image: url(../img/bg-careers.jpg);
}

.bg-testimonial {
  padding: 8rem 0rem;
}

.bg-slim {
  padding: 4rem 0rem;
}

.bg-documentary {
  padding: 6rem 0rem;
}

.bg-hero-mission {
  background: no-repeat center bottom 30%;
  background-size: cover;
  padding-top: 15rem;
  padding-bottom: 12rem;
  background-image: url(../img/bg-hero-mission.jpg);
}

.bg-forest-intro {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-forest-intro.jpg);
}

.bg-location {
  background: no-repeat center bottom;
  background-size: cover;
  background-image: url(../img/location.jpg);
}

.bg-collage {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/collage3.jpg);
}

.bg-cta-about {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-cta-about.jpg);
}

.bg-home-frustrated {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-home-frustrated.jpg);
}

.bg-action {
  background: no-repeat 50% 30%;
  background-size: cover;
  background-image: url(../img/bg-action.jpg);
}

.bg-virtual {
  background: no-repeat 50% 60%;
  background-size: cover;
  background-image: url(../img/bg-virtual.jpg);
}

.bg-journey {
  background: no-repeat 50% 30%;
  background-size: cover;
  background-image: url(../img/bg-journey.jpg);
}

.bg-promise-home {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-promise-home.jpg);
}

.bg-cbf {
  background: no-repeat top;
  background-size: cover;
  background-image: url(../img/bg-cbf.jpg);
}

.bg-parents {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-parents.jpg);
}

.bg-elementary-hero {
  background: no-repeat 50% 60%;
  background-size: cover;
  background-image: url(../img/bg-elementary.jpg);
}

.bg-studios{
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/montessori.png);
}

.bg-tuition{
  background: no-repeat 50% 50%;
  background-size: cover;
  background-image: url(../img/bg-tuition.jpg);
}

.bg-day {
  background: no-repeat 50% 50%;
  background-size: cover;
  background-image: url(../img/bg-day.jpg);
}

.bg-forest-hero {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-forest.jpg);
}

.bg-registration {
  background: no-repeat 50% 60%;
  background-size: cover;
  background-image: url(../img/bg-registration.jpg);
}

.bg-resources {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-resources.jpg);
}

.bg-nest-hero {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-nest-hero.jpg);
}

.bg-middle {
  background: no-repeat 50% 25%;
  background-size: cover;
  background-image: url(../img/bg-middle.jpg);
}

.bg-cta-community-middle {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-cta-community-middle.jpg);
}

.bg-apply-hero {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-apply-hero.jpg);
}

.bg-different {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-different.jpg);
}

.bg-cta-community-elementary {
  background: no-repeat top;
  background-size: cover;
  background-image: url(../img/bg-cta-community-elementary.jpg);
}

.bg-parents-hero {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/journey-3.jpg);
}

.bg-huddle {
  background: no-repeat center 30%;
  background-size: cover;
  background-image: url(../img/huddle.jpg);
}

.bg-extended {
  background: no-repeat center 20%;
  background-size: cover;
  background-image: url(../img/group.jpg);
}

.bg-bench {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bench.jpg);
}

.bg-tree {
  background: no-repeat center 40%;
  background-size: cover;
  background-image: url(../img/tree.jpg);
}

.bg-yard {
  background: no-repeat center 10%;
  background-size: cover;
  background-image: url(../img/yard.jpg);
}

.testimonial {
  font-size: 2rem;
  line-height: 1.7;
  font-weight: 400;
  .quotes {
    display:inline-block;
    width: 120px;
    height: 120px;
    margin-bottom: 30px;
    background: no-repeat center;
    background-image: url(../img/icon-quotes.svg);
    background-size: cover;
  }

  .attribution {
    font-size: 1rem;
    font-weight: normal;
    margin-top: 1.5rem;
  }

  &.white-letters { color: white; .attribution { color: white;} }

  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
}

.play {
  max-height: 60px;
}

.signature {
  max-height: 60px;
  max-width: 180px;
}

.editorial {
  text-align: left;

  &.center { text-align: center; }
  .white { color: white; }

  h3 {
    font-size: 1.75rem;
  }

  p {
    font-weight: 300;
    font-size: 15px;
    color: $black;
    line-height: 1.62;
  }
}

.box {
  padding: 30px;
}

.faqs-box {
  text-align: left;
  padding: 0 30px;
  margin: 3rem 0;

  .question-wrapper {
    padding: 30px 0;
    border-bottom: 1px solid rgba(black, 0.075);

    &.active .question::after { background-image: url(../img/icon-chevron-up.png); }
  }

  .question {
    color: $primary;
    font-weight: 500;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    &::after {
      content: "";
      margin-left: auto;
      padding-left: 50px;
      display: block;
      position: relative;
      width: 100%;
      max-width: 21px;
      height: 11px;
      background-image: url(../img/icon-chevron-down.png);
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .answer {
    display: none;
    font-size: 1rem;
    margin-top: 1rem;
    line-height: 1.72;
    p { color: $black; font-weight: 300; }
    p:last-child { margin: 0; }
  }
}

.team-member {
  max-height: 80px;
  border-radius: 50%;
}

.icon {
  max-height: 80px;
}

footer {
  background-color: white;;
  padding: 5rem 0;
  border-top: 1px solid rgba(black, 0.075);

  a {
    color: $grey;
    display: block;
    margin: 1rem;
    transition: 200ms all;

    &:hover, &:focus, &:active {
      color: lighten($secondary, 15%);
      text-decoration: none;
    }

    @media (max-width: 991px) {
      display: block;
    }
  }

  .copyright {
    font-size: 0.8rem;
  }

  @media (max-width: 991px) {
    text-align: center;
  }
}

.social-icons {
  .social-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 6px;
    height: 48px;
    width: 48px;
    font-size: 24px;
    text-align: center;
    color: white;
    background: $primary;
    border-radius: 50%;
    transition: 200ms all;

    &:hover {
      background: lighten($blue, 10%);
    }
  }
}

.contact-card {
  display: flex;
  align-items: center;
  font-weight: 300;
  img { margin-right: 1rem; }

  h5 {
    font-size: 1rem;
    margin-bottom: 8px;
    color: $primary;
    font-weight: bold;
  }

  p {
    margin-bottom: 0;
  }

  @media(max-width: 991px) {
    margin-bottom: 1rem;
  }
}

.feature {
  h5 {
    color: $secondary;
    margin: 1rem 0;
    font-size: 1.4rem;
  }
  p {
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.72;
  }
}

.img-wrapper {
  height: 280px;
  width: auto;
  max-width: 100%;

  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }

  &.vertical {
    height: 400px;
  }
}

.img-mega-wrapper {
  position: relative;
  margin-top: 40px;
  img {
    position: absolute;
    right: 0;
  }

  @media(max-width: 991px) {
    display: none;
  }
}

.img-circle{
  border-radius: 50%;
  width: 100%;
}

.img-featured {
  max-height: 450px;
}

.parents-club-books {
    a {
        font-weight: 700;
        text-decoration: underline;
    }
}

.parents-club-video {
    overflow: hidden;
    iframe {
        background-color: black;
        width: 100%;
        height: 310px;
    }

    p {
        text-align: center;
    }

    &.col-md-4{
        iframe{
            height: 200px;
        }
    }

}

.person-title {
  margin-top: 3px;
  font-size: 0.66em;
  color: $grey;
}

.person-wrapper {
  padding: 3rem 0;

  img {
    width: 100%;
    height: auto;
    max-height: 140px;
    max-width: 140px;
    object-fit: cover;
  }
}

.tools-images {
  @media (max-width: 991px) {
    img {
      max-height: 80px;
      max-width: 180px;
    }
  }
}

.location-row {
  [class*=' col-'], .col-md-4 {
    min-height: 400px;
  }

  .box {
    padding: 5rem 30px;
    height: 100%;
  }
}

.learning-boxes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 0;

  .learning-box {
    display: inline-block;
    vertical-align: top;
    margin: 4px;
    max-width: 400px;
    border-radius: 3px;
    overflow: hidden;

    img {
      max-width: 100%;
    }

    .learning-box-body {
      width: 100%;
      padding: 30px;
    }

    h2 {
      margin-bottom: 4px;
      font-size: 1.5rem;
      text-align: center;
      font-weight: 600;
    }

    h5 {
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 1rem;
      text-align: center;
      margin-left: 8px;
    }

    hr {
      margin-top: 2rem;
      margin-bottom: 2rem;
      border: 0;
      border-top: 4px solid white;
      max-width: 30px;
      &.black { border-top-color: $black; }
    }

    .highlight {
      font-size: 1.25rem;
      position: relative;
      text-align: center;
      margin: 1rem 0;
    }

    .underline {
      display: inline-block;
    }

  }
}

.learning-box.horizontal {
  @media (min-width: 992px) {
    width: 100%;
    max-width: 100%;
    display: flex;

    .img-wrapper {
      min-width: 360px;
      max-width: 360px;
      width: 360px;
      height: 100%;
    }
  }
}

.box-event {
  padding: 30px;
  border-radius: 3px;
  background-color: white;
  margin-bottom: 4px;

  .title {
    font-weight: 600;
  }

  .button {
    margin-bottom: 0;
  }
}

.schedule {
  text-align: left;

  .time-slot {
    padding: 5px 15px;
    border-radius: 4px;
    display: flex;
    border: dashed 2px $tertiary;
    margin-bottom: 8px;
    color: $tertiary;

    .time {
      width: 75px;
    }
    .description {
      width: 100%;
    }

    &.solid {
      background-color: $primary;
      color: white;
      border: 2px solid $primary;
    }

    &.red {
      background-color: $tertiary;
      border-color: $tertiary;
      color: white;
    }

    &.bg-light-blue {
      border: 2px solid $secondary;
      background-color: $secondary;
    }
  }
}

.deconstructed-logo {
  width: 100%;

  .part {
    position: relative;
    max-width: 50%;
    margin-left: 0;
    transition: 200ms all;

    img {
      max-width: 100%;
    }

    .arrow {
      position: absolute;
      right: 10px;
      bottom: 0;
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: white;
      box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3);
    }

    &:hover {
      cursor: pointer;
      margin-left: 20px;
    }

    &.active {
      margin-left: 50%;

      .arrow {
        display: none;
      }

      .content {
        display: block;
      }

      &:hover { margin-left: calc(50% - 20px);}
    }

    &:first-child { margin-bottom: -14%;}
    &:nth-child(2) { margin-bottom: -10%;}
    &:nth-child(3) { margin-bottom: -5%;}
    &:nth-child(4) { margin-bottom: -3%;}
  }
}

.deconstructed-logo-content {
  display: flex;
  justify-content: flex-end;

  h2 {
    font-weight: 600;
    font-size: 1.5rem;
  }

  .content {
    max-width: 500px;
    display: none;

    &.active {
      display: flex;
      .icon { margin-right: 2rem; }
    }
  }
}

.school-comparison {
  ul {
    text-align: left;
    list-style-type: none;
    counter-reset: item 0;
    padding-left: 0;
    margin-top: 2rem;
  }

  li {
    position: relative;
    padding-left: 0rem;
    counter-increment: item 1;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    color: $black;
    padding-left: 3rem !important;
    transition: 200ms all ease-in-out;

    &:last-child { margin-bottom: 0; }

    &:before {
      position: absolute;
      left: 0;
      width: 36px;
      height: 36px;
      content: "";
      background-repeat: no-repeat;
      background-size: contain;
      transition: 200ms all ease-in-out;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .traditional li {
    &:before {
      background-image: url(../img/cog.png);
    }

    &:hover:before {
      animation: rotate 1.5s linear infinite;
    }
  }

  .new li {
    &:before {
      background-image: url(../img/leaf.png);
      transform: rotate(0deg);
    }

    &:hover:before {
      transform: scale(1.1) rotate(30deg) translateX(-5px);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 98px;
  }

  .content p {
    display: inline;
  }

  .content p:last-child {
    margin-bottom: 0;
  }

  @media(max-width: 991px) {
    h2, h3, h4 {
      font-weight: bold;
      font-size: 1rem;
    }

    .box {
      padding: 0.5rem;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 164px;
      font-size: 0.8rem;
    }

    li {
      padding-left: 1.5rem;
      margin-bottom: 0.5rem;
    }

    li::before {
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
      min-height: 1.5rem;
      font-size: 0.85rem;
    }
  }
}

.img-cont-16-9{
  width: 100%;
  height: 0;
  box-sizing: content-box;
  position: relative;
  padding-top: 56.25%;

  img{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.map{
  iframe{
    width: 100%;
    display: block;
  }
}

#bookModal {
  .modal-header{
    border-bottom: 0;
  }
  .modal-body {
    padding: 0px 30px 30px;
  }
}

.difference-copy {
  margin-bottom: 5rem;
}

.difference-box {
  margin-bottom: 5rem;
 &::before {
   content: "";
   width: 60px;
   height: 60px;
   display: block;
   border-radius: 100px;
   margin-bottom: 30px;
   background-size: 30px 32px;
   background-repeat: no-repeat;
   background-position: center;
 }
}

.difference-box.difference-powerful {
  &::before { background-image: url(../img/icon-powerful.svg); background-color: $primary; }

}

.difference-box.difference-learner {
  &::before { background-image: url(../img/icon-learner.svg); background-color: $secondary; }
}

.difference-box.difference-socratic {
  &::before { background-image: url(../img/icon-socratic.svg); background-color: $tertiary; }
}

.difference-box.difference-real {
  &::before { background-image: url(../img/icon-real.svg); background-color: $quaternary;}
}

.resource.book {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: white;
  border-radius: 4px;
  text-decoration: none;
  box-shadow: 3px 3px 8px 0px rgba(46, 61, 73, 0.1);
  text-align: left;
  height: 100%;
  transition: 200ms all;

  .icon {
    font-size: 2rem;
    margin-right: 1.5rem;
  }

  .title {
    display: block;
    font-size: 1rem;
  }

  .author {
    display: block;
    color: $grey;
    font-size: 0.9rem;
    margin-top: 0.25rem;
  }

  .link {
    font-size: 0.9rem;
    color: $secondary;
  }

  &:hover, &:focus, &:active {
    transform: translateY(-3px);
  }
}

.video-link {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 1rem;
  background-color: $bg-grey;
  text-align: left;
  box-shadow: 3px 3px 8px 0px rgba(46, 61, 73, 0.1);

  img {
    width: 100%;
    height: 160px;
    object-fit: cover;
  }

  .title {
    color: $black;
  }

  .video-link-footer {
    display: flex;
    align-items: center;
    padding: 1rem;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    min-height: 36px;
    min-width: 36px;
    color: white;
    border-radius: 50%;
    background-color: $primary;
    font-size: 1rem;
    margin-right: 1.5rem;
  }

  .author {
    display: block;
    color: $grey;
    font-size: 0.9rem;
    margin-top: 0.25rem;
  }

  &:hover, &:focus, &:active {
    text-decoration: none;
    transform: translateY(-3px);

    .icon {
      background-color: $secondary;
    }
  }
}

.lead {
    font-size: 2rem !important;
    line-height: 1.7;
    font-weight: 300;
}

.pb-blog-wrapper, .pb-blog-post {
  margin-top: 14rem;
  @media(max-width: 991px) {
    margin-top: 12rem;
  }
}

.icon-wrapper {
  position: relative;
  width: 140px;
  height: 140px;
  margin: 0 auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .fa {
    font-size: 80px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.navigation-box {
  position: relative;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: 200ms all;
  padding: 2rem 30px;

  &:hover, &:focus, &:active {
    transform: translateY(-5px);
  }
}

@keyframes rotate{
  to{ transform: rotate(360deg); }
}

.sticky-top {
  top: 130px;
  z-index: 20;
}

// Job description page
.job-description {
  padding-top: 124px;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__image {
    align-self: center;
    width: 248px;
    max-width: 248px;
    
    img {
      width: 100%;
    }
  }

  &__title {
    margin: 32px 0 32px 0;
    color: $blue;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    align-self: center;
  }

  strong {
    font-size: 1.125rem;
  }

  ul {
    list-style: none;
  }

  ul li::before {
    content: "\2022"; 
    color: #0e88a4;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  &__apply {
    margin: 32px 0 0 0;
    outline: #0e7f9a 2px solid;
    padding: 1rem 1.5rem;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    width: 100%;
    max-width: 100%;
    align-self: center;
  }
  
  &__howto {
    font-size: 1.125rem;
    color: #ff6600;
    font-weight: bold;
  }

  &__mailto {
    color: #ff6600;
    text-align: center;
    align-self: center;
    font-weight: bold;
    font-size: 1.125rem;

    :hover {
      color: #dd5800;
    }
  }

  &__bottom {
    margin-top: 32px;
    margin-bottom: 0;
    font-size: 1rem;
    align-self: center;
  }

  @media (min-width: 991px) {
    padding-top: 164px;
  }
}
.enroll-link-wrapper{
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
}
.enroll-link{
  min-width: 247px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}