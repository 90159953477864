.carousel {
  width: 100%;
  h3 {
    font-size: 2.8rem;
  }
  p {
    font-size: 1.3rem;
    @media (max-width: 767px) {
      margin-bottom: 20px !important;
    }
  }
  .carousel-item {
    min-height: 450px;
    position: relative;

    @media(max-width: 991px) {
      min-height: 600px
    }
  }

  .carousel-item-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel-indicators {
    li {
      box-sizing: inherit;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      padding: 5px;
      margin-right: 8px;
      margin-left: 8px;
      cursor: pointer;
      border: 2px solid white;
      background-color: transparent;
      box-shadow: 0px 1px 2px rgba(100, 100, 100, 0.2);
      background-clip: inherit;

      &.active { background-color: white; }
    }
  }
}

#heroCarousel {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .carousel-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .carousel-item {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .carousel-item:nth-child(1) {
    background-position: 50% 25%;
  }
}
