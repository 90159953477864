html, body {
  width: 100%;
  height: 100%;
  font-size: 16px;
}

body {
  font-family: 'Lato', sans-serif, serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";;
  display: block;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  color: $black;
}

@mixin serif {
  font-family: 'Lato', sans-serif, serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";;;
}

.serif {
  @include serif;
}

h1, h2, h3, h4, h5 {
  font-weight: 300;
  color: $black;
  @include serif;
  font-family: 'Lato', sans-serif;
}

.section-heading, .section-title {
  font-size: 2.4rem;
  font-weight: 600;
  @include serif;

  @media (max-width: 991px) { font-size: 2.5rem; }
}

h1 {
  font-size: 3.5rem;
  font-weight: 600;
  @media (max-width: 991px) { font-size: 2.5rem; }
}

h2 {
  font-size: 2.25rem;
  @media (max-width: 991px) { font-size: 1.85rem; }
}

h3 {
  font-size: 1.5rem;
}

a {
  color: $primary;
  font-weight: 500;
  transition: 200ms all;

  &:hover {
    color: darken($secondary, 10%);
  }
}

.uppercase {
  text-transform: uppercase;
}

.hide, .hidden {
  display: none;
}

.center, .centered {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.bold, strong {
  font-weight: bold;
}

.lead {
  font-size: 2rem;
  line-height: normal !important;
  font-weight: 300;

  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
}

.lead-sm {
  font-size: 1.5rem;
  font-weight: 300;
  @media (max-width: 991px) {
    font-size: 1.33rem;
  }
}

.light {
  font-weight: 300;
}

.no-effects {
  &:hover, &:focus, &:active { outline: none; }
}

.white-letters {
  color: white;
  h1, h2, h3, h4, h5, h6, p, a, ul, li {
    color: white;
  }

  .styled-number-list li:before {
    color: white;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.bring-to-front {
  z-index: 10;
}

.hidden-mobile {
  @media(max-width: 991px) {
    display: none;
  }
}

.centered-mobile {
  @media(max-width: 991px) {
    text-align: center;
  }
}

.nowrap {
  white-space: nowrap;
}

.link-absolute {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.separator-bottom { margin-bottom: 3rem; }
.separator-top { margin-top: 3rem; }

.visible-sm {
  @media(min-width: 1201px) {
    display: none;
  }
}

.img-size-std {
  max-height: 500px;
}

hr.stylized {
  display: block;
  margin: 3rem auto;
  max-width: 80px;
  height: 4px;
  border: 0;
  box-shadow: none;
}
.hey-parents{
  background: url(../img/plant-rotated.png) no-repeat left bottom, url(../img/circle-bg.png) no-repeat right bottom;
  background-size: 500px;
  background-color: rgb(248, 248, 248);

  @media(max-width: 768px) {
    background-image: none;
  }
  img{
    height: 400px; 
    width: 400px; 
    object-fit: cover;  
    @media(max-width: 991px) {
      height: 300px; 
      width: 300px; 
    }  
  }
}
.curriculum-grid{
  gap: 12px;
}
.curriculum-grid img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.curriculum-box{
  height: 400px;
  padding: 0;
  display: flex;
}
.curriculum-box img{
  padding: 8px;
}
.curriculum-text{
  margin: 8px;
  height: calc(100% - 16px);
  width: calc(100% - 16px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.curriculum-text h3{
  color: #FFF;
  margin-bottom: 16px;
}
.curriculum-squares li{
  display: flex;
  gap: 18px;
}
.curriculum-squares li:before{
  margin-right: 8px !important;
}
.curriculum-squares li strong {
  width: 78px;
}
.curriculum-grid h2{
  margin-top: 18px;
  margin-bottom: 10px;
}
.bg-apply-join-ms{
  background-image: url(../img/join-middle-school.jpeg);
  background-size: cover;
  background-position: center center;
}
.bg-middle-hero{
  background-image: url(../img/middle-school-bg.jpeg);
  background-size: cover;
  background-position: center 26%;
}
.middle-school-studio h2{
  font-size: 2rem;
}
.bg-middle-school-break{
  background-image: url(../img/pagebreak.jpeg);
  background-size: cover;
  background-position: center 90%;
  padding: 14rem 2rem;
}